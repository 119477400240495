import { selectors } from '../selectors'
import { callback, input, rule } from './enums'
import regex from './regex'
import mask from './masks'
import { address, identificationNumber, validatePinCode } from './rulesetExternal'
import { household } from './rulesetCustom'
import { showNextFormStep } from '../registration/formSteps'
import { storePreRegistrationData } from '../registration/preregistration'
import { validateCheckBox, validateDate, validateInput, validateRadio, validateRange, validateSelect } from './helpers'
import { elementIsCheckbox, elementIsDate, elementIsInput, elementIsRadio, elementIsRange, elementIsSelect } from '../shared'
import { callMeBackRequest, helpMeRequest, newsletterRequest } from '../ajaxCalls'
import { initFormResubmitDuplicityPrevention, unBindConfirmPageLeaving } from '../registration/windowEvents'

export const event = {
    [input.submit]: 'click',
    [input.checkbox]: 'change',
    [input.date]: 'paste focusout',
    [input.radio]: 'change',
    [input.range]: 'change',
    [input.select]: 'change',
    [input.text]: 'paste focusout validateSmartForm',
    [input.file]: '...TODO...',
    [input.textarea]: '...TODO...',
}

export const errorEvent = {
    [input.submit]: 'click',
    [input.checkbox]: 'change',
    [input.date]: 'change paste keyup keydown',
    [input.radio]: 'change',
    [input.range]: 'change',
    [input.select]: 'change',
    [input.text]: 'change paste keyup keydown',
    [input.file]: '...TODO...',
    [input.textarea]: '...TODO...',
}

export const type = {
    [input.checkbox]: elementIsCheckbox,
    [input.date]: elementIsDate,
    [input.radio]: elementIsRadio,
    [input.range]: elementIsRange,
    [input.select]: elementIsSelect,
    [input.text]: elementIsInput,
    [input.file]: () => {},
    [input.textarea]: () => {},
}

export const fn = {
    [input.checkbox]: validateCheckBox,
    [input.date]: validateDate,
    [input.radio]: validateRadio,
    [input.range]: validateRange,
    [input.select]: validateSelect,
    [input.text]: validateInput,
    [input.file]: '...TODO...',
    [input.textarea]: '...TODO...',
}

export const ruleset = {
    // --------------------- HomePage ---------------------

    [selectors.submits.homePageStep]: {
        description: 'HomePageForm',
        callbacks: {},
        inputs: {
            [selectors.inputs.homePageAmountOI]: {
                [rule.ranged]: true,
            },
            [selectors.inputs.homePageAmountTI]: {
                [rule.ranged]: true,
            },
            [selectors.inputs.homePageDaysOI]: {
                [rule.ranged]: true,
            },
            /* [selectors.inputs.homePagePromoCodeOI]: {
                [rule.external]: promoCode,
                [flag.detached]: true, // Do not block form submit
            },
            [selectors.inputs.homePagePromoCodeTI]: {
                [rule.external]: promoCode,
                [flag.detached]: true, // Do not block form submit
            }, */
        },
    },

    [selectors.buttons.callMeBackBtn]: {
        description: 'HomepageFormCallMe',
        callbacks: {
            [callback.onSuccess]: callMeBackRequest,
        },
        inputs: {
            [selectors.inputs.homePageCallMeBack]: {
                [rule.mask]: mask.phoneNumber,
                [rule.regex]: regex.phoneNumber,
            },
        },
    },

    // --------------------- Registration ---------------------

    [selectors.submits.registrationStep1]: {
        description: 'RegistrationForm Step1',
        callbacks: {
            [callback.onSuccess]: showNextFormStep,
            [callback.onValidate]: storePreRegistrationData,
        },
        inputs: {
            [selectors.inputs.name]: {
                [rule.regex]: regex.name,
            },
            [selectors.inputs.surname]: {
                [rule.regex]: regex.surname,
            },
            [selectors.inputs.email]: {
                [rule.regex]: regex.email,
            },
            [selectors.inputs.phoneNumber]: {
                [rule.mask]: mask.phoneNumber,
                [rule.regex]: regex.phoneNumber,
            },
            [selectors.inputs.nin]: {
                [rule.mask]: mask.nin,
                [rule.external]: identificationNumber,
            },
            [selectors.inputs.approvalPersonalInfo]: {
                [rule.selected]: true,
            },
        },
    },
    [selectors.submits.registrationStep2]: {
        description: 'RegistrationForm Step2',
        callbacks: {
            [callback.onSuccess]: showNextFormStep,
            [callback.onValidate]: storePreRegistrationData,
        },
        inputs: {
            [selectors.inputs.street]: {
                [rule.regex]: regex.street,
                [rule.external]: address,
            },
            [selectors.inputs.streetNumber]: {
                [rule.regex]: regex.streetNumber,
                [rule.external]: address,
            },
            [selectors.inputs.city]: {
                [rule.regex]: regex.city,
                [rule.external]: address,
            },
            [selectors.inputs.zipCode]: {
                [rule.mask]: mask.zipCode,
                [rule.external]: address,
            },
            [selectors.inputs.delStreet]: {
                [rule.regex]: regex.street,
                [rule.external]: address,
            },
            [selectors.inputs.delStreetNumber]: {
                [rule.regex]: regex.streetNumber,
                [rule.external]: address,
            },
            [selectors.inputs.delCity]: {
                [rule.regex]: regex.city,
                [rule.external]: address,
            },
            [selectors.inputs.delZipCode]: {
                [rule.mask]: mask.zipCode,
                [rule.external]: address,
            },
            [selectors.inputs.maritalStatus]: {
                [rule.selected]: true,
            },
            [selectors.inputs.housing]: {
                [rule.selected]: true,
            },
            [selectors.inputs.minorChildren]: {
                [rule.selected]: true,
            },
        },
    },
    [selectors.submits.registrationStep3]: {
        description: 'RegistrationForm Step3',
        callbacks: {
            [callback.onSuccess]: showNextFormStep,
            [callback.onValidate]: storePreRegistrationData,
        },
        inputs: {
            [selectors.inputs.netIncome]: {
                [rule.mask]: mask.income,
                [rule.regex]: regex.income,
                [rule.custom]: household,
            },
            [selectors.inputs.householdNetIncome]: {
                [rule.mask]: mask.income,
                [rule.regex]: regex.income,
                [rule.custom]: household,
            },
            [selectors.inputs.costs]: {
                [rule.mask]: mask.costs,
                [rule.regex]: regex.costs,
                [rule.custom]: household,
            },
            [selectors.inputs.householdCosts]: {
                [rule.mask]: mask.costs,
                [rule.regex]: regex.costs,
                [rule.custom]: household,
            },
            [selectors.inputs.householdPersons]: {
                [rule.mask]: mask.persons,
                [rule.custom]: household,
            },
            [selectors.inputs.householdPersonsWithIncome]: {
                [rule.mask]: mask.persons,
                [rule.custom]: household,
            },
            [selectors.inputs.moneySource]: {
                [rule.selected]: true,
            },
            [selectors.inputs.workingYears]: {
                [rule.selected]: true,
            },
            [selectors.inputs.employer]: {
                [rule.regex]: regex.employer,
            },
            [selectors.inputs.selfEmployerIc]: {
                [rule.mask]: mask.selfEmployerIco,
            },
            [selectors.inputs.employerContract]: {
                [rule.selected]: true,
            },
            [selectors.inputs.employerContractEnd]: {
                [rule.regex]: regex.employerContractEnd,
            },
        },
    },
    [selectors.submits.registrationStep4]: {
        description: 'RegistrationForm Step4',
        callbacks: {
            [callback.onValidate]: storePreRegistrationData,
            [callback.beforeSubmit]: () => unBindConfirmPageLeaving() || initFormResubmitDuplicityPrevention(),
        },
        inputs: {
            [selectors.inputs.nationality]: {
                [rule.selected]: true,
            },
            [selectors.inputs.idCardNumber]: {
                [rule.mask]: mask.idCardNumber,
            },
            [selectors.inputs.accountNumber]: {
                [rule.mask]: mask.accountNumber,
            },
            [selectors.inputs.bank]: {
                [rule.selected]: true,
            },
            /* [selectors.inputs.registrationPromoCode]: {
                [rule.external]: promoCode,
                [flag.detached]: true, // Do not block form submit
            }, */
        },
    },
    [selectors.submits.registrationStepComplete]: {
        description: 'RegistrationForm StepComplete',
        callbacks: {},
        inputs: {
            [selectors.inputs.maritalStatusComplete]: {
                [rule.selected]: true,
            },
            [selectors.inputs.housingComplete]: {
                [rule.selected]: true,
            },
            [selectors.inputs.moneySourceComplete]: {
                [rule.selected]: true,
            },
            [selectors.inputs.netIncomeComplete]: {
                [rule.mask]: mask.income,
                [rule.regex]: regex.income,
                [rule.custom]: household,
            },
            [selectors.inputs.householdNetIncomeComplete]: {
                [rule.mask]: mask.income,
                [rule.regex]: regex.income,
                [rule.custom]: household,
            },
            [selectors.inputs.costsComplete]: {
                [rule.mask]: mask.costs,
                [rule.regex]: regex.costs,
                [rule.custom]: household,
            },
            [selectors.inputs.householdCostsComplete]: {
                [rule.mask]: mask.costs,
                [rule.regex]: regex.costs,
                [rule.custom]: household,
            },
            [selectors.inputs.householdPersonsComplete]: {
                [rule.mask]: mask.persons,
                [rule.custom]: household,
            },
            [selectors.inputs.householdPersonsWithIncomeComplete]: {
                [rule.mask]: mask.persons,
                [rule.custom]: household,
            },
            [selectors.inputs.workingYearsComplete]: {
                [rule.selected]: true,
            },
            [selectors.inputs.employerComplete]: {
                [rule.regex]: regex.employer,
            },
            [selectors.inputs.selfEmployerIcComplete]: {
                [rule.mask]: mask.selfEmployerIco,
            },
            [selectors.inputs.employerContractComplete]: {
                [rule.selected]: true,
            },
            [selectors.inputs.employerContractEndComplete]: {
                [rule.regex]: regex.employerContractEnd,
            },
        },
    },
    [selectors.submits.stepPinCode]: {
        description: 'RegistrationForm Step PinCode',
        callbacks: {
            onSuccess: () => validatePinCode(selectors.inputs.pinCode),
        },
        inputs: {
            [selectors.inputs.pinCode]: {
                [rule.mask]: mask.pinCode,
            },
            [selectors.inputs.activationApproval]: {
                [rule.selected]: true,
            },
            [selectors.inputs.activationApproval2]: {
                [rule.selected]: true,
            },
            [selectors.inputs.activationApproval3]: {
                [rule.selected]: true,
            },
        },
    },

    // --------------------- Client ---------------------

    [selectors.submits.customerStepActualizeData]: {
        description: 'CustomerForm Step ActualizeData',
        callbacks: {},
        inputs: {
            [selectors.inputs.maritalStatusClientComplete]: {
                [rule.selected]: true,
            },
            [selectors.inputs.housingClientComplete]: {
                [rule.selected]: true,
            },
            [selectors.inputs.moneySourceClientComplete]: {
                [rule.selected]: true,
            },
            [selectors.inputs.minorChildrenClientComplete]: {
                [rule.selected]: true,
            },
            [selectors.inputs.employerContractClientComplete]: {
                [rule.selected]: true,
            },
            [selectors.inputs.householdNetIncomeClientComplete]: {
                [rule.mask]: mask.income,
                // [rule.custom]: household, // ??
            },
            [selectors.inputs.householdCostsClientComplete]: {
                [rule.mask]: mask.income,
                // [rule.custom]: household, // ??
            },
            [selectors.inputs.netIncomeClientComplete]: {
                [rule.mask]: mask.income,
                // [rule.custom]: household, // ??
            },
            [selectors.inputs.costsClientComplete]: {
                [rule.mask]: mask.income,
                // [rule.custom]: household, // ??
            },
            [selectors.inputs.householdPersonsClientComplete]: {
                [rule.mask]: mask.persons,
                // [rule.custom]: household, // ??
            },
            [selectors.inputs.householdPersonsWithIncomeClientComplete]: {
                [rule.mask]: mask.persons,
                // [rule.custom]: household, // ??
            },
            [selectors.inputs.employerClientComplete]: {
                [rule.regex]: regex.employer,
                // [rule.external]: employer, // ??
            },
            [selectors.inputs.employerContractEndClientComplete]: {
                [rule.regex]: regex.employerContractEnd,
            },
        },
    },

    [selectors.submits.stepPaymentSchedulePinCode]: {
        description: 'PaymentSchedule PinCode',
        callbacks: {
            onSuccess: () => validatePinCode(selectors.inputs.paymentSchedulePinCode),
        },
        inputs: {
            [selectors.inputs.paymentSchedulePinCode]: {
                [rule.mask]: mask.pinCode,
            },
            [selectors.inputs.paymentScheduleApproval]: {
                [rule.selected]: true,
            },
        },
    },

    [selectors.submits.stepLoanPinCode]: {
        description: 'Loan PinCode',
        callbacks: {
            onSuccess: () => validatePinCode(selectors.inputs.loanPinCode),
        },
        inputs: {
            [selectors.inputs.loanPinCode]: {
                [rule.mask]: mask.pinCode,
            },
            [selectors.inputs.loanApproval]: {
                [rule.selected]: true,
            },
            [selectors.inputs.loanApproval2]: {
                [rule.selected]: true,
            },
            [selectors.inputs.loanApproval3]: {
                [rule.selected]: true,
            },
        },
    },

    [selectors.submits.loanFormSave]: {
        description: 'Loan Form client',
        callbacks: {},
        inputs: {
            [selectors.inputs.clientLoanAmountOI]: {
                [rule.ranged]: true,
            },
            [selectors.inputs.clientLoanAmountTI]: {
                [rule.ranged]: true,
            },
            [selectors.inputs.clientLoanDaysOI]: {
                [rule.ranged]: true,
            },
            /* [selectors.inputs.loanPromoCode]: {
                [rule.external]: promoCode,
                [flag.detached]: true, // Do not block form submit
            }, */
        },
    },

    [selectors.submits.stepLoanExtensionPinCode]: {
        description: 'LoanExtension PinCode',
        callbacks: {
            onSuccess: () => validatePinCode(selectors.inputs.loanExtensionPinCode),
        },
        inputs: {
            [selectors.inputs.loanExtensionPinCode]: {
                [rule.mask]: mask.pinCode,
            },
            [selectors.inputs.loanExtensionApproval]: {
                [rule.selected]: true,
            },
        },
    },

    [selectors.submits.stepLoanTopUpPinCode]: {
        description: 'LoanTopUp PinCode',
        callbacks: {
            onSuccess: () => validatePinCode(selectors.inputs.loanTopUpPinCode),
        },
        inputs: {
            [selectors.inputs.loanTopUpPinCode]: {
                [rule.mask]: mask.pinCode,
            },
            [selectors.inputs.loanTopUpApproval]: {
                [rule.selected]: true,
            },
        },
    },

    // --------------------- Other ---------------------

    [selectors.submits.login]: {
        description: 'Login ClientsSection',
        callbacks: {},
        inputs: {
            [selectors.inputs.loginUsername]: {
                [rule.regex]: regex.email,
            },
            [selectors.inputs.loginPassword]: {
                [rule.regex]: regex.password,
            },
        },
    },

    [selectors.submits.unsubscribeSms]: {
        description: 'Unsubscribe SMS',
        callbacks: {},
        inputs: {
            [selectors.inputs.phoneNumberUnsubscribe]: {
                [rule.mask]: mask.phoneNumber,
                [rule.regex]: regex.phoneNumber,
            },
        },
    },

    [selectors.submits.newsletter]: {
        description: 'Newsletter',
        callbacks: {
            [callback.onSuccess]: newsletterRequest,
        },
        inputs: {
            [selectors.inputs.newsletter]: {
                [rule.regex]: regex.email,
            },
        },
    },

    [selectors.submits.helpMe]: {
        description: 'HelpMe',
        callbacks: {
            [callback.onSuccess]: helpMeRequest,
        },
        inputs: {
            [selectors.inputs.phoneNumberHelpMe]: {
                [rule.mask]: mask.phoneNumber,
                [rule.regex]: regex.phoneNumber,
            },
        },
    },

    [selectors.submits.resetPasswordSend]: {
        description: 'Reset Password',
        callbacks: {},
        inputs: {
            [selectors.inputs.resetPasswordNin]: {
                [rule.mask]: mask.nin,
            },
            [selectors.inputs.resetPasswordEmail]: {
                [rule.regex]: regex.email,
            },
        },
    },
}
